@import "/styles/variables.scss";

.container {
  pointer-events: none;
}

.progressBar {
  background-color: $app-blue-grey;
  width: 100%;
  height: 4px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
}
