@import "./css-resets.scss";
@import "./typography.scss";
@import "./variables.scss";

html,
body {
  padding: 0;
  margin: 0;
}

body {
  background-color: $app-biege;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.noscroll {
  overflow: hidden;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

// WYSWIG images
p > img.aligncenter {
  display: block;
  margin: 0 auto;
}

p > img.alignright {
  display: block;
  margin-left: auto;
}
