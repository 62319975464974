/**
 * Force cookie bot to use custom font
 */
#CybotCookiebotDialog {
  font-family: "canada-type-gibson" !important;
}

/**
 * Learn more link
 */
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText > a {
  font-weight: 400 !important;
  color: white !important;
  text-decoration: underline !important;
}

/**
 * Show details link
 */
#CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  color: white !important;
}

#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonDescription,
#CybotCookiebotDialog #CybotCookiebotDialogBodyButtonDecline,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CybotCookiebotDialog #CybotCookiebotDialogPoweredByText > a > span {
  font-weight: 500 !important;
}
