@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  padding: 40px 32px 32px;
  background-color: $app-light-biege;
  box-shadow: 0px 4px 50px rgba(black, 0.05);
  border-radius: 4px;
  animation: fadein-form 0.3s ease;

  h3 {
    @extend %app-heading-base, %text-2xl;

    text-align: center;
    margin-bottom: 32px;
    color: $app-black;
  }

  .twoColumns {
    display: flex;

    & > * {
      flex: 1 1 0;
    }

    & > .formInput:not(:first-of-type) {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  .formInput {
    position: relative;

    & > label {
      display: block;
      margin-bottom: 8px;
    }

    & > input[type="text"],
    & > input[type="email"] {
      @extend %app-typography;

      width: 100%;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;

      &::placeholder {
        color: rgba($app-blue-grey, 0.5);
      }

      &:focus {
        outline: 2px solid $app-blue-grey;
      }
    }

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  .footer {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
  }

  .formGdpr {
    display: flex;
    align-items: flex-start;

    & > input[type="checkbox"] {
      position: relative;
      flex-shrink: 0;
      margin: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 4px;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 7px;
        width: 8px;
        height: 16px;
        transform: rotate(45deg);
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
        transition: border-color 0.15s ease;
      }

      &:checked::before {
        border-color: $app-green;
      }
    }

    & > label {
      margin-left: 16px;
      font-size: 14px;
      line-height: 22px;
    }

    & > label a {
      color: $app-green;
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: $app-green-accent;
      }
    }
  }

  [type="submit"] {
    @extend %app-typography;

    width: 145px;
    padding: 12px 24px;
    border: none;
    color: white;
    background: #8ca489;
    border-radius: 4px;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.kommuns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  min-height: 50px;

  .kommun {
    @extend %app-typography;

    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    border: 1px solid $app-muted-blue;
    border-radius: 4px;
    color: $app-muted-blue;
  }

  .kommunClose {
    position: relative;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid $app-blue-grey;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba($app-blue-grey, 0.1);
    }

    &::before {
      content: "";
      width: 16px;
      height: 1px;
      background-color: $app-blue-grey;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      content: "";
      width: 16px;
      height: 1px;
      background-color: $app-blue-grey;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.close {
  position: absolute;
  right: 12px;
  top: calc(50% + 4px);

  width: 24px;
  height: 24px;
  background-color: rgba($app-blue-grey, 0.2);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba($app-blue-grey, 0.4);
  }

  &::before {
    content: "";
    width: 16px;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    content: "";
    width: 16px;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.formFeedback {
  @extend %app-typography-p22;

  margin-top: 16px;
  text-align: center;
  color: $app-blue-grey;
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 24px 20px;

    .twoColumns {
      flex-direction: column;

      & > .formInput:not(:first-of-type) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  .footer {
    flex-direction: column;

    .actions {
      width: 100%;
      margin-top: 24px;
    }

    [type="submit"] {
      width: 100%;
    }
  }
}
