@import "./variables.scss";

%text-sm {
  font-size: 14px;
  line-height: 16px;
}

%text-base {
  font-size: 16px;
  line-height: 24px;
}

%text-lg {
  font-size: 18px;
  line-height: 26px;
}

%text-xl {
  font-size: 18px;
  line-height: 26px;
}

%text-1xl {
  font-size: 20px;
  line-height: 28px;
}

%text-2xl {
  font-size: 24px;
  line-height: 28px;
}

%text-3xl {
  font-size: 32px;
  line-height: 32px;
}

%text-4xl {
  font-size: 44px;
  line-height: 44px;
}

%text-5xl {
  font-size: 64px;
  line-height: 64px;
}

%font-gibson {
  font-family: "canada-type-gibson", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 300;
}

%font-p22 {
  font-family: "p22-mackinac-pro", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
}

%app-typography {
  @extend %text-base, %font-gibson;

  color: $app-almost-black;
}

%app-typography-p22 {
  @extend %app-typography, %font-p22;
}

%app-heading-base {
  @extend %app-typography-p22;

  font-weight: 400;
  letter-spacing: -0.01em;
  color: $app-blue-grey;
}

%app-heading {
  @extend %app-heading-base, %text-5xl;
}

%app-subheading {
  @extend %app-heading-base, %text-4xl;
}

%app-title {
  @extend %app-heading-base, %text-3xl;
}

%app-subtitle {
  @extend %app-heading-base, %text-2xl;
}

@media only screen and (max-width: 900px) {
  %text-sm-mobile {
    font-size: 12px;
    line-height: 14px;
  }

  %text-base-mobile {
    font-size: 16px;
    line-height: 24px;
  }

  %text-lg-mobile {
    font-size: 16px;
    line-height: 24px;
  }

  %text-xl-mobile {
    font-size: 20px;
    line-height: 28px;
  }

  %text-3xl-mobile {
    font-size: 28px;
    line-height: 28px;
  }

  %text-4xl-mobile {
    font-size: 32px;
    line-height: 36px;
  }
  %text-5xl-mobile {
    font-size: 36px;
    line-height: 40px;
  }

  %app-typography-mobile {
    @extend %text-base-mobile;

    font-family: "canada-type-gibson", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 300;
    color: $app-almost-black;
  }

  %app-typography-p22-mobile {
    @extend %app-typography-mobile;

    font-family: "p22-mackinac-pro", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
  }

  %app-heading-base-mobile {
    @extend %app-typography-p22-mobile;

    font-weight: 400;
    letter-spacing: -0.01em;
    color: $app-blue-grey;
  }

  %app-heading-mobile {
    @extend %app-heading-base-mobile, %text-5xl-mobile;
  }

  %app-subheading-mobile {
    @extend %app-heading-base-mobile, %text-4xl-mobile;
  }

  %app-title-mobile {
    @extend %app-heading-base-mobile, %text-3xl-mobile;
  }
}
