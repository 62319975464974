.container {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  transition: all 0.2s ease;
  --handle-length: 14px;

  &.open {
    border-color: transparent;
    transform: rotate(45deg);
    --handle-length: 24px;
  }
}

.handle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 1px;
  width: var(--handle-length);
  background-color: white;

  &:nth-child(2) {
    height: var(--handle-length);
    width: 1px;
  }
}
