@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  @extend %app-typography;

  position: fixed;
  bottom: var(--bottom-offset, 20px);
  right: 20px;
  z-index: 100;

  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  padding: 12px 24px;
  color: white;
  background-color: $app-green;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;

  .label {
    margin-right: 8px;
  }

  &:hover {
    background-color: $app-green-accent;
  }

  &:focus {
    outline: none;
  }

  &.open {
    padding: 12px;
    background-color: $app-muted-blue;
    border-radius: 50%;

    .label {
      display: none;
    }
  }

  &.open:hover {
    background-color: $app-primary-dark;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    bottom: var(--bottom-offset, 40px);
    right: 40px;
  }
}
