@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.link {
  @extend %app-typography, %app-typography-mobile;

  display: inline-flex;
  justify-content: center;
  padding: 12px 24px;
  background-color: transparent;
  color: $app-almost-black;
  border: 1px solid $app-almost-black;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%) contrast(92%);
  }
}

.linkGreen {
  border-color: $app-green;
  background-color: $app-green;
  color: white;

  &:hover {
    background-color: $app-green-accent;
    color: white;
    filter: none;
  }
}

.hasIcon {
  padding-left: 24px;
  padding-right: 18px;

  .label {
    margin-right: 10px;
  }
}

.unstyled {
  padding: 4px 0;
  border: 0;

  &:hover {
    filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%) contrast(92%);
  }
}

.linkGreen.hasIcon .icon {
  filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
}

@media only screen and (max-width: 900px) {
  .link {
    padding: 8px 16px;
  }
}
