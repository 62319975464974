@import "/styles/font-bases.scss";

.formDescription {
  @extend %text-xl;

  max-width: 664px;
  margin: 32px auto 0;
  text-align: center;
}

.form {
  max-width: 664px;
  margin: 60px auto 0;
}

.filler {
  height: 20px;
}
