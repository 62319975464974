.links {
  display: flex;
  justify-content: center;

  & > *:not(:first-of-type) {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .links {
    flex-direction: column;
    align-items: center;

    & > *:not(:first-of-type) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
