@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  position: absolute;
  top: calc(24px + 8px + 24px + 40px);
  left: 0;
  z-index: 1000;

  width: 100%;
  max-height: 235px;
  overflow-y: scroll;
  padding: 16px 12px 12px;
  background-color: white;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  animation: fade-in 0.2s ease;
  margin-bottom: 8px;
}

.label {
  @extend %text-sm;

  text-transform: uppercase;
  color: $app-muted-blue;
  opacity: 0.5;
  letter-spacing: 0.05em;
  margin: 0 8px 6px;

  &:not(:first-of-type) {
    margin-top: 6px;
  }
}

.item {
  @extend %app-typography;

  padding: 6px 8px;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: rgba($app-blue-grey, 0.1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.empty {
  display: flex;
  justify-content: center;
  padding-bottom: 4px;
  color: rgba($app-blue-grey, 0.5);
}
