@import "/styles/font-bases.scss";
@import "/styles/variables.scss";

.container {
  h1 {
    @extend %app-typography-p22, %text-5xl;
  }

  h2 {
    @extend %app-typography-p22, %text-4xl;
  }

  h3 {
    @extend %app-typography-p22, %text-3xl;
  }

  h4 {
    @extend %app-typography-p22, %text-2xl;
  }

  h5 {
    @extend %app-typography-p22, %text-1xl;
  }

  p {
    @extend %app-typography, %text-lg;
  }

  em {
    font-style: italic;
  }

  p + h1,
  p + h2 {
    margin-top: 48px;
  }

  p + h3,
  p + h4,
  ul + h4 {
    margin-top: 80px;
  }

  p + h5,
  p + h6 {
    margin-top: 40px;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h1 + table,
  h2 + table,
  h3 + table {
    margin-top: 32px;
  }

  h4 + p,
  h5 + p,
  h6 + p,
  p + p,
  ul + p,
  h4 + table,
  h5 + table,
  h6 + table,
  p + table,
  ul + table {
    margin-top: 24px;
  }

  h4 + h5,
  ul + h5 {
    margin-top: 32px;
  }

  figure {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 100% !important;

    img {
      max-width: 100%;
      height: inherit;
      width: 100%;
    }

    figcaption {
      @extend %app-typography, %text-base;

      color: #39485a;
      margin-top: 16px;
    }
  }

  p + figure {
    margin-top: 40px;
  }

  blockquote {
    padding: calc(40px + 45px + 20px) 0 40px;
    position: relative;

    p {
      @extend %app-typography-p22, %text-xl;

      color: $app-blue-grey;
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      width: 45px;
      height: 45px;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("/images/icon-quote.svg");
      background-repeat: no-repeat;
    }
  }

  ul {
    margin: 32px 0 0 10px;
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 20px;

    li {
      @extend %app-typography, %text-lg;
    }

    li:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  a {
    text-decoration: underline;
    color: $app-green;
    transition: opacity 0.3s ease;
    display: inline-block;

    &:hover {
      opacity: 0.85;
    }
  }

  strong {
    font-weight: 500;
  }

  table {
    width: 100%;

    th {
      font-weight: 500;
    }

    th,
    td {
      border: 1px solid $app-black;
      padding: 6px 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    -webkit-text-size-adjust: none;

    table {
      display: block;
      max-width: fit-content;
      overflow-x: auto;
    }
  }
}
