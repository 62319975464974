@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  padding: 100px 40px;
  overflow-x: hidden;
}

.light {
  background-color: $app-light-biege;
}

.lighter {
  background-color: $app-lighter-biege;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.labelContainer {
  text-align: center;
  margin-bottom: 80px;
  overflow: hidden;
}

.label {
  @extend %text-sm;

  position: relative;
  display: inline-block;
  color: $app-blue-grey;
  margin: 0;
  text-transform: uppercase;

  &::before,
  &::after {
    content: "";
    width: 88px;
    height: 1px;
    background-color: $app-blue-grey;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: calc(100% + 32px);
  }

  &::after {
    right: calc(100% + 32px);
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &.hasLabel {
    margin-top: -32px;
  }

  &.hasLink {
    justify-content: space-between;
  }
}

.title {
  @extend %app-subheading, %app-subheading-mobile;

  text-align: center;
}

.description {
  max-width: 896px;
  margin: 32px auto 0;
  text-align: center;
}

.mobileLinkWrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 60px 20px;
  }

  .labelContainer {
    margin-bottom: 40px;
  }

  .titleWrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .label {
    &::before,
    &::after {
      width: 44px;
    }

    &::before {
      left: calc(100% + 16px);
    }

    &::after {
      right: calc(100% + 16px);
    }
  }
}

@media only screen and (max-width: 480px) {
  .label {
    &::before {
      left: calc(100% + 7px);
    }

    &::after {
      right: calc(100% + 7px);
    }
  }
}
