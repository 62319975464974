// biege shades
$app-biege: #f7f0ea;
$app-light-biege: #f8f3ee;
$app-lighter-biege: #faf6f2;

// blue shades
$app-blue-grey: #617999;
$app-muted-blue: #4d607a;
$app-primary-dark: #39485a;
$app-almost-black: #2b2c37;
$app-black: #2a3441;

// green shades
$app-green: #8ca489;
$app-green-accent: #708d6d;

// red shades
$app-red: #be5f5f;
$app-red-accent: #b85151;

// component-specific variables
$card-bg-biege: #fbf9f6;

$app-white: #fff;
